button.button,
a.button{
  --text: var(--white);
  --background: var(--black);
  --border: var(--black);
  --textHover: var(--redLight);
  --backgroundHover: var(--black);
  --borderHover: var(--black);

  &.ghost{
    --text: var(--black);
    --background: transparent;
    --border: var(--black);
    --textHover: var(--white);
    --backgroundHover: var(--red);
    --borderHover: var(--red);
  }

  &.grayscale{
    --text: var(--white);
    --background: var(--black);
    --border: var(--black);
    --textHover: var(--white);
    --backgroundHover: var(--black);
    --borderHover: var(--black);

    &.ghost{
      --text: var(--black);
      --background: transparent;
      --border: var(--black);
      --textHover: var(--white);
      --backgroundHover: var(--black);
      --borderHover: var(--black);
    }
  }

  &.dark{
    --text: var(--black);
    --background: var(--white);
    --border: var(--white);
    --textHover: var(--white);
    --backgroundHover: var(--red);
    --borderHover: var(--red);

    &.ghost{
      --text: var(--white);
      --background: transparent;
      --border: var(--white);
      --textHover: var(--white);
      --backgroundHover: var(--red);
      --borderHover: var(--red);
    }

    &.grayscale{
      --text: var(--black);
      --background: var(--white);
      --border: var(--white);
      --textHover: var(--black);
      --backgroundHover: var(--white);
      --borderHover: var(--white);

      &.ghost{
        --text: var(--white);
        --background: transparent;
        --border: var(--white);
        --textHover: var(--black);
        --backgroundHover: var(--white);
        --borderHover: var(--white);
      }
    }
  }
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.75rem;
  min-height: 2.75rem;

  padding: 0 var(--spacing7);

  color: var(--text);
  font-size: var(--fontSize2);
  font-family: var(--primaryFont);
  white-space: nowrap;
  text-transform: uppercase;
  text-decoration: none;

  background-color: var(--background);

  border: 2px solid var(--border);
  border-radius: 3rem;

  box-shadow: var(--elevation0);

  transition: all var(--speed1) ease-in-out;

  user-select: none;

  svg{
    width: auto;
    height: 1em;
    path{
      transition: fill var(--speed1) ease-in-out;

      fill: var(--text);
    }

    &:first-child{
      margin-right: var(--spacing4);
    }
    &:last-child{
      margin-left: var(--spacing4);
    }
  }

  &.icon{
    width: 2.75rem;
    padding: 0;

    border-radius: 100%;
    aspect-ratio: 1/1;
    svg{
      width: auto;
      height: 1.5rem;
      margin: 0;
    }
  }


  &:hover:not(.disabled),
  &.ghost:hover:not(.disabled),
  &.grayscale:hover:not(.disabled),
  &.dark.ghost:hover:not(.disabled),
  &.dark.grayscale:hover:not(.disabled),{
    cursor: pointer;
    --text: var(--textHover);
    --background: var(--backgroundHover);
    --border: var(--borderHover);
    box-shadow: var(--elevation4);
  }

  &.disabled{
    filter: grayscale(100%) brightness(50%);

    pointer-events: none;
  }
}
