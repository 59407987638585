section.fold{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;

  *{
    z-index: 0;
  }

  .poemWrapper{
    position: fixed;
    top: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    pointer-events: none;
  }

  .shards{
    z-index: 100;
  }

  .controls{
    display: grid;
    align-content: center;
    align-self: center;
    justify-content: center;
    margin-top: var(--spacing6);
    >*{
      display: flex;
      grid-row: 1;
      grid-column: 1;
      gap: var(--spacing6);
      justify-content: center;
    }
  }

  .modalsWrapper{
    z-index: 101;
  }
}
.red{
  color: var(--red);
}
