.shards{
  position: relative;

  display: grid;
  align-items: center;
  justify-content: center;
  width: min(90vw, 50vh);
  height: 50vh;

  pointer-events: none;

  >div{
    top: 0;
    left: 0;

    grid-row: 1;
    grid-column: 1;
    width: auto;
    max-width: 100%;

    height: 100%;
  }
  svg{
    top: 0;
    left: 0;

    width: auto;
    max-width: 100%;
    height: auto;

    overflow: visible;
    aspect-ratio: 1/1;

    &:not(:first-child){
      position: absolute;

      pointer-events: none;
    }

    path{
      transform-box: view-box;
    }
  }
  .background{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 0;

    width: 100vw;
    min-width: 100vw;
    height: 100vh;
    min-height: 100vh;

    background-color: var(--white);
    opacity: 0;

    pointer-events: none;
  }
}
