.poem{
  display: grid;
  grid-template-rows: 1fr;
  >div{
    position: relative;

    display: inline;

    grid-row: 1;
    grid-column: 1;
    width: 100%;

    font-weight: 700;
    font-size: var(--fontSize3);
    line-height: var(--lineHeight);
    white-space: normal;

    text-align: center;
    text-transform: uppercase;
    @media (max-width: 600px){
      display: grid;
      grid-template-columns: 1fr;
    }

    .red{
      color: var(--red);
    }
  }
}
