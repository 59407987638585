.sceneHeadline{
  margin-top: var(--spacing6);

  .headline{
    position: relative;

    display: grid;

    font-weight: 700;
    font-size: var(--fontSize6);
    letter-spacing: 0.1em;
    text-align: center;
    text-transform: uppercase;
    @media (max-width: 950px){font-size: var(--fontSize5);}
    @media (max-width: 750px){font-size: var(--fontSize4);}
    @media (max-width: 550px){font-size: var(--fontSize2);}
    div{
      display: inline-block;
      grid-row: 1;
      grid-column: 1;
    }
  }

  .subheadline{
    display: grid;
    align-content: center;

    align-self: center;
    justify-content: center;
    margin-top: var(--spacing5);

    font-size: var(--fontSize4);
    letter-spacing: 0.05em;
    text-align: center;
    text-transform: uppercase;
    @media (max-width: 950px){font-size: var(--fontSize3);}
    @media (max-width: 750px){
      margin-top: var(--spacing5);

      font-size: var(--fontSize2);
    }
    @media (max-width: 550px){font-size: var(--fontSize1);}

    div{
      display: inline-block;
      grid-row: 1;
      grid-column: 1;
      gap: var(--spacing6);
      justify-content: center;

      &:nth-child(1){
        z-index: 0;

        pointer-events: none;
      }

      &:not(:nth-child(1)){
        display: grid;
        grid-template-columns: repeat(3, min-content);
      }

      .button{
        margin: var(--spacing5) auto 0 auto;
      }
    }
  }
}
