.rocketModal{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;

  .inner{
    position: relative;

    display: grid;
    max-width: 500px;
    max-height: 80vh;
    padding: var(--spacing5);
    overflow-x: hidden;
    overflow-y: auto;

    text-align: center;
    @media (max-width: 700px){font-size: var(--fontSize1);}

    >*{
      z-index: 1;

      display: flex;
      flex-direction: column;
      grid-row: 1;
      grid-column: 1;
      align-items: center;
      justify-content: center;
    }

    .intro{
      box-sizing: border-box;
      max-width: 100vw;
      padding: var(--spacing7) var(--spacing5);

      .superHeadline{
        font-weight: 700;
        font-size: var(--fontSize1);
        text-transform: uppercase;
        @media (max-width: 500px){font-size: var(--fontSize0);}

        span{
          color: #666666;
        }
      }

      .headline{
        margin-top: var(--spacing5);

        font-weight: 700;
        font-size: var(--fontSize3);
        text-transform: uppercase;
        @media (max-width: 700px){font-size: var(--fontSize2);}
      }
      .list{
        display: grid;
        grid-template-columns: 1fr;
        gap: var(--spacing6);
        margin-top: var(--spacing6);

        .entry{
          >:nth-child(1){
            display: flex;
            gap: var(--spacing4);
            align-items: center;
            justify-content: center;

            .date{
              font-weight: 700;
              text-transform: uppercase;
            }
            .before,
            .after{
              width: 3rem;
              height: 2px;

              background-color: var(--black);
            }
          }
        }
      }
    }

    .statistics{
      @media (max-width: 700px){font-size: var(--fontSize1);}
      .headline{
        font-weight: 700;
        font-size: var(--fontSize5);
        @media (max-width: 700px){font-size: var(--fontSize2);}

        text-transform: uppercase;
      }
      .list{
        display: grid;
        grid-template-columns: 1fr;
        gap: var(--spacing5);
        margin-top: var(--spacing6);

        .entry{

          >*{
            margin-bottom: var(--spacing3);
          }

          >:nth-child(1){
            display: flex;
            gap: var(--spacing4);
            align-items: center;
            justify-content: center;

            .date{
              font-weight: 700;
              text-transform: uppercase;
            }
            .before,
            .after{
              width: 3rem;
              height: 2px;

              background-color: var(--black);
            }
          }
        }
      }
    }

    .red{
      color: var(--red);
    }
    .buttons{
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: var(--spacing5);
      margin-top: var(--spacing6);
    }
  }
}
